<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="lancamentos"
      :sort-desc="true"
      :items-per-page="-1"
      hide-default-footer
      class="data-tables data-tables__row-click"
    >
      <template v-slot:item.circle="{ item }">
        <v-tooltip bottom color="darkblue">
          <template v-if="item.tipo_af === 'E'" v-slot:activator="{ on }">
            <v-icon v-on="on" color="success"> mdi-plus-circle </v-icon>
          </template>
          <span>
            {{ $tc("global.entrada") }}
          </span>
        </v-tooltip>
        <v-tooltip bottom color="darkblue">
          <template v-if="item.tipo_af === 'S'" v-slot:activator="{ on }">
            <v-icon v-on="on" color="error"> mdi-minus-circle </v-icon>
          </template>
          <span>
            {{ $tc("global.saida") }}
          </span>
        </v-tooltip>
        <v-tooltip bottom color="darkblue">
          <template v-if="item.tipo_af === 'N'" v-slot:activator="{ on }">
            <v-icon v-on="on" color="tocs_gray_2"> mdi-alpha-n-circle </v-icon>
          </template>
          <span> Essa entrada não acrescenta ao total do caixa </span>
        </v-tooltip>
        <v-tooltip bottom color="darkblue">
          <template v-if="item.tipo_af === 'C'" v-slot:activator="{ on }">
            <v-icon v-on="on" color="tocs_red"> mdi-alpha-c-circle </v-icon>
          </template>
          <span> Esse cancelamento não acrescenta ao total do caixa </span>
        </v-tooltip>
      </template>
      <template v-slot:item.tipo="{ item }">
        {{
          item.tipo === "venda"
            ? $tc("global.venda")
            : item.tipo === "devolucao"
            ? $tc("global.devolucao")
            : item.tipo === "conta_receber"
            ? $tc("global.contasReceber")
            : item.tipo === "conta_pagar"
            ? $tc("global.contasPagar")
            : item.tipo === "gasto"
            ? $tc("global.gasto")
            : ""
        }}
      </template>
      <template v-slot:item.condicao_pagamento="{ item }">
        {{
          item.condicao_pagamento == 1
            ? $tc("global.aVista")
            : $tc("global.aPrazo")
        }}
      </template>
      <template v-slot:item.valor="{ item }">
        {{ item.moeda_sigla }}
        <span v-if="item.moeda_sigla === 'G$'">
          {{ item.valor | guarani }}
        </span>
        <span v-else>
          {{ item.valor | currency }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="darkblue">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="primary"
              @click.stop="openInfo(item)"
            >
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc("global.informacoes") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.numero="{ item }">
        {{ item.numero }}
      </template>
    </v-data-table>
    <div class="mt-2 d-flex">
      <div>
        <span class="text-subtitle-1">{{ $tc("global.total") }}: </span>
        <span
          class="text-subtitle-1"
          :class="totalComputed >= 0 ? 'success--text' : 'error--text'"
          ><Currency :moeda_sigla="moeda_sigla" :valor="totalComputed"
        /></span>
      </div>
      <div>
        <v-tooltip bottom color="darkblue">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <span class="text-subtitle-1 ml-10"
                >{{ $tc("global.total") }} N:
              </span>

              <span class="text-subtitle-1"
                ><Currency :moeda_sigla="moeda_sigla" :valor="totalN"
              /></span>
            </div>
          </template>
          <span> Essa entrada não acrescenta ao total do caixa </span>
        </v-tooltip>
      </div>
    </div>
    <div>
      <v-icon size="small">mdi-information-outline</v-icon
      ><span class="text-caption">
        O valor total refere-se à soma de todas as entradas e saídas realizadas
        no lançamento em lote</span
      >
    </div>
    <DialogVendaInfo
      v-if="dialogDetalheVenda"
      :dialogVendaInfo.sync="dialogDetalheVenda"
      :venda="itemDetalhe"
      :venda_parcelas="venda_parcelas"
    />
    <DialogDetalhesDevolucaoEmLoteAf
      v-if="dialogDetalheDevolucao"
      :show.sync="dialogDetalheDevolucao"
      :devolucaoProp="itemDetalhe"
    />
    <DialogDetalhesContaReceber
      v-if="dialogDetalheContaReceber"
      :dialogConta.sync="dialogDetalheContaReceber"
      :conta_id="itemDetalhe.id"
      visualizar
    />
    <DialogDetalhesContaPagar
      v-if="dialogDetalheContaPagar"
      :dialogConta.sync="dialogDetalheContaPagar"
      :conta_id="itemDetalhe.id"
      visualizar
    />
    <DialogDetalhesGasto
      v-if="dialogDetalheGasto"
      :dialogGasto.sync="dialogDetalheGasto"
      :item="itemDetalhe"
    />
  </div>
</template>

<script>
import { getVendaInfo } from "@/api/vendas/vendas.js";

export default {
  name: "ListaLancamentosEmLoteAf",

  props: {
    lancamentos: {
      type: Array,
    },
    total: {
      type: Number,
    },
  },

  data() {
    return {
      dialog: false,
      dialogDetalheVenda: false,
      dialogDetalheContaReceber: false,
      dialogDetalheContaPagar: false,
      dialogDetalheGasto: false,
      dialogDetalheDevolucao: false,
      itemDetalhe: null,
      venda_parcelas: [],
    };
  },

  methods: {
    openInfo(item) {
      if (item.tipo == "venda") {
        this.openVendaInfo(item);
      }
      if (item.tipo == "conta_receber") {
        this.openContaReceberInfo(item);
      }
      if (item.tipo == "conta_pagar") {
        this.openContaPagarInfo(item);
      }
      if (item.tipo == "gasto") {
        this.openGastoInfo(item);
      }
      if (item.tipo == "devolucao") {
        this.openDevolucaoInfo(item);
      }
    },
    openContaReceberInfo(item) {
      this.itemDetalhe = { ...item };
      this.dialogDetalheContaReceber = true;
    },
    openContaPagarInfo(item) {
      this.itemDetalhe = { ...item };
      this.dialogDetalheContaPagar = true;
    },
    openGastoInfo(item) {
      this.itemDetalhe = { ...item };
      this.dialogDetalheGasto = true;
    },
    openDevolucaoInfo(item) {
      this.itemDetalhe = { ...item };
      this.dialogDetalheDevolucao = true;
    },
    async openVendaInfo(item) {
      await getVendaInfo(item.id)
        .then((response) => {
          this.itemDetalhe = response;
          if (this.itemDetalhe.parcelas) {
            this.venda_parcelas = this.itemDetalhe.parcelas;
          }
          this.dialogDetalheVenda = true;
        })
        .catch(() => {});
    },
  },

  components: {
    Currency: () => import("@/components/global/Currency.vue"),
    DialogVendaInfo: () =>
      import("@/views/dashboard/caixas-pdv/components/DialogVendaInfo.vue"),
    DialogDetalhesContaReceber: () =>
      import("./DialogDetalhesContaReceber.vue"),
    DialogDetalhesContaPagar: () => import("./DialogDetalhesContaPagar.vue"),
    DialogDetalhesGasto: () => import("./DialogDetalhesGasto.vue"),
    DialogDetalhesDevolucaoEmLoteAf: () =>
      import("./DialogDetalhesDevolucaoEmLoteAf.vue"),
  },

  computed: {
    moeda_sigla() {
      return this.lancamentos[0].moeda_sigla;
    },
    totalComputed() {
      let total = 0;
      for (const lancamento of this.lancamentos) {
        if (lancamento.condicao_pagamento == 1) {
          if (lancamento.tipo_af === "E") {
            total += Number(lancamento.valor);
          } else if (lancamento.tipo_af === "S") {
            total -= Number(lancamento.valor);
          }
        }
      }
      return total;
    },
    totalN() {
      let total = 0;
      for (const lancamento of this.lancamentos) {
        if (lancamento.tipo_af === "N") {
          total += Number(lancamento.valor);
        }
      }
      return total;
    },
    headers() {
      return [
        {
          text: "",
          value: "circle",
          align: "start",
          sortable: false,
        },
        {
          text: this.$tc("global.numero"),
          value: "numero",
          align: "start",
          sortable: false,
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipo",
          align: "start",
          sortable: false,
        },
        {
          text:
            this.$tc("global.cliente") + "/" + this.$tc("global.fornecedor"),
          value: "cliente_fornecedor",
          align: "start",
          sortable: false,
        },
        {
          text: this.$tc("global.pagamento"),
          value: "condicao_pagamento",
          align: "start",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "start",
          sortable: false,
        },
        {
          text: this.$tc("global.detalhe", 2),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    totalComputed: {
      handler() {
        this.$emit("update:total", this.totalComputed);
      },
      immediate: true,
    },
  },
};
</script>